import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { ModalTypeEnum } from "../../utils";
import { useModals } from "../../contexts/modals";
import { RituelParametersForm } from "../../components";

function ProfilParameters() {
  const { openModal } = useModals();

  return (
    <div>
      <RituelParametersForm />
      <hr className="border-3" />
      <button className="btn btn-primary" onClick={() => openModal(ModalTypeEnum.Programmation)}>
        <FontAwesomeIcon icon={faCalendarDays} /> Programmation
      </button>
    </div>
  );
}

export default ProfilParameters;
