import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

function Help() {
  return (
    <div className="my-0 mx-auto" style={{ maxWidth: 960 }}>
      <h1>Aide générale</h1>

      <p className="mt-3 fw-bold">
        L'atelier des problèmes est une application qui permet à l'élève de s'entraîner en résolution de problèmes en lien avec les apprentissages réalisés par l'enseignant en classe. Les problèmes
        sont proposés selon une progression et une progression du CP au CM2 définies dans l'application et personnalisable par l'enseignant.
      </p>

      <p className="mt-3">L'application comprend trois modules&nbsp;:</p>
      <ul>
        <li>«&nbsp;L'atelier&nbsp;» pour un entraînement individuel, sans authentification&nbsp;;</li>
        <li>«&nbsp;En classe&nbsp;» pour un usage régulier, avec un suivi des élèves pour le professeur&nbsp;;</li>
        <li>«&nbsp;À la maison&nbsp;» pour un usage dans un cadre familial.</li>
      </ul>

      <p className="mt-3">
        Le module «&nbsp;En classe&nbsp;» est indexé sur le calendrier scolaire et accessible uniquement les jours d'école, du lundi au vendredi. L'application n'est pas accessible aux élèves pendant
        les vacances scolaires.
      </p>

      <h2 className="mt-3">Sommaire</h2>

      <ul className="mt-3">
        <li className="mb-2">
          <Link to={{ hash: "atelier" }}>Module «&nbsp;L'Atelier&nbsp;»</Link>
        </li>
        <li className="mb-2">
          <Link to={{ hash: "en-classe" }}>Module «&nbsp;En classe&nbsp;»</Link>
        </li>
        <li className="mb-2">
          <Link to={{ hash: "a-la-maison" }}>Module «&nbsp;A la maison&nbsp;»</Link>
        </li>
        <li className="mb-2">
          <Link to={{ hash: "tableau-de-bord" }}>Le tableau de bord de suivi des élèves</Link>
        </li>
        <li>
          <Link to={{ hash: "programmation" }}>La programmation/progression en résolution de problèmes</Link>
        </li>
      </ul>

      <hr />

      <h2 id="atelier" className="mt-3">
        Module «&nbsp;L'Atelier&nbsp;»
      </h2>

      <p className="mt-3">Ce module ne nécessite pas d'authentification. L'élève choisit son niveau de classe et accède directement à une partie.</p>

      <p className="mt-3">
        Chaque partie propose 5 problèmes tirés au sort dans la banque collaborative M@ths en-vie en fonction d'une programmation et d'une progression définies de base dans l'application.
      </p>

      <p className="mt-3">Pour chaque partie, l'élève dispose de 3 jokers qu'il peut s'il le souhaite utiliser lui permettant&nbsp;:</p>
      <ul>
        <li>de passer un problème s'il ne le comprend pas, par exemple pour des raisons de vocabulaire (coût&nbsp;: 1 joker)&nbsp;;</li>
        <li>d'accéder à une aide «&nbsp;Coup de pouce&nbsp;» lui donnant des indications pour pouvoir résoudre le problème (coût&nbsp;: 1 joker)&nbsp;;</li>
        <li>d'accéder à une aide «&nbsp;À l'aide&nbsp;» (uniquement pour le cycle 3) lui donnant la démarche complète pour résoudre le problème (coût&nbsp;: 2 jokers).</li>
      </ul>

      <p className="mt-3">À l'affichage du problème, l'élève peut&nbsp;:</p>
      <ul>
        <li>écouter une lecture de l'énoncé via l'icône associée&nbsp;;</li>
        <li>demander un autre tirage du même type&nbsp;;</li>
        <li>demander l'affichage de l'aide «&nbsp;Coup de pouce&nbsp;» ou «&nbsp;À l'aide&nbsp;»&nbsp;;</li>
        <li>répondre.</li>
      </ul>

      <div className="d-block text-center mt-3">
        <img src="img/helpGame.webp" alt="Exemple d'une partie" width="80%" />
      </div>

      <p className="mt-3">L'élève saisit sa réponse grâce à un pavé numérique dans la phrase réponse qui s'affiche.</p>

      <p className="mt-3">En cas de réponse erronée, l'application affiche la bonne réponse et une explication que l'élève peut écouter.</p>

      <p className="mt-3">À la fin de la partie, l'application indique&nbsp;:</p>
      <ul>
        <li>le nombre de jokers utilisés&nbsp;;</li>
        <li>le badge obtenu («&nbsp;Expert&nbsp;», «&nbsp;Confirmé&nbsp;» et «&nbsp;Novice&nbsp;») calculé par un algorithme selon les réussites et le nombre de badges utilisés&nbsp;;</li>
        <li>et pour chacun des 5 problèmes,&nbsp;s'il a été réussi (barre verte), s'il a été réussi avec une aide (barre orange), s'il a été échoué (barre rouge).</li>
      </ul>

      <p className="mt-3">
        À l'issue de la partie, il est proposé à l'élève de faire une autre partie ou d'arrêter. Dans ce dernier cas, l'application renvoie sur l'écran d'accueil permettant de choisir, pour un autre
        élève, un niveau de classe.
      </p>

      <h2 id="en-classe" className="mt-3">
        Module «&nbsp;En classe&nbsp;»
      </h2>

      <p className="mt-3">
        Le module «&nbsp;En classe&nbsp;» s'appuie sur le même type de parties proposées dans le module «&nbsp;L'atelier&nbsp;» (voir descriptif ci-dessus) avec des fonctionnalités
        complémentaires&nbsp;:
      </p>
      <ul>
        <li>une authentification enseignant permettant aux élèves d'accéder à leur profil personnel et à l'enseignant, de suivre les progrès de ses élèves&nbsp;;</li>
        <li>un tableau de paramétrage de la progression/programmation (voir descriptif ci-dessous)&nbsp;;</li>
        <li>une évaluation diagnostique lors de la première partie effectuée dans chaque période (5 évaluations).</li>
      </ul>

      <p className="mt-3">
        Dans son profil, l'enseignant configure le niveau de classe de chacun de ses élèves représentés chacun par un pictogramme. Cela débloque l'accès au profil élève correspondant.
      </p>

      <p className="mt-3">
        L'enseignant s'authentifie sur tous les postes afin de donner l'accès au tableau des pictogrammes permettant à chaque élève de retrouver son profil personnel. Le pictogramme
        «&nbsp;professeur&nbsp;» permet à l'enseignant de pouvoir tester l'application telle qu'un élève peut l'utiliser.
      </p>

      <div className="d-block text-center mt-3">
        <img src="img/helpAvatar.webp" alt="Page des avatars" width="80%" />
      </div>

      <p className="mt-3">
        Lors de sa première partie dans la période, l'élève est soumis à une évaluation diagnostique qui se compose de 3 problèmes (sans aide, sans correction) du type étudié dans la période,
        déterminant ainsi la difficulté des problèmes qui vont lui être proposés.
      </p>

      <p className="mt-3">L'élève peut réaliser jusqu'à 3 parties dans la journée et 10 dans la période.</p>

      <p className="mt-3">
        Si un élève n'a par réussi un problème, une correction s'affiche. Si l'élève ne comprend pas la correction, il a la possibilité de notifier l'enseignant afin que ce dernier puisse lui venir en
        aide. Une notification s'affiche alors pour cet élève dans le tableau de bord de l'enseignant.
      </p>

      <p className="mt-3">
        Le pictogramme "Enseignant", en rouge dans le tableau des pictogrammes, permet à celui-ci de disposer d'un profil comme pour un élève afin de pouvoir se mettre à sa place et tester
        l'application.
      </p>

      <h2 id="a-la-maison" className="mt-3">
        Module «&nbsp;A la maison&nbsp;»
      </h2>

      <p className="mt-3">Ce module est en cours de développement.</p>

      <h2 id="tableau-de-bord" className="mt-3">
        Le tableau de bord de suivi des élèves
      </h2>

      <p className="mt-3">
        Le tableau de bord permet de paramétrer le niveau des élèves et suivre la progression des élèves. Il vous sera demandé à nouveau votre mot de passe afin que les élèves ne puissent pas accéder
        au tableau de bord.
      </p>

      <div className="d-block text-center mt-3">
        <img src="img/helpDashboard.webp" alt="Tableau de bord enseignant" width="80%" />
      </div>

      <p className="mt-3">
        Pour le paramétrage, attribuer un pictogramme à chaque élève. Cocher tous les pictogrammes d'élèves d'un niveau afin de paramétrer en lot le niveau et recommencer l'opération pour tous les
        niveaux de la classe. Si un pictogramme reste grisé dans le panneau d'accueil, c'est que son niveau n'a pas été configuré. Il n'est pas possible de changer le niveau d'un élève une fois qu'il
        a commencé à utiliser l'application avec son profil.
      </p>

      <p className="mt-3">Le tableau de bord permet de voir&nbsp;:</p>
      <ul>
        <li>la réussite à l'évaluation&nbsp;diagnostique grâce à un code couleur&nbsp;: vert (3 problèmes réussis), orange (1 à 2 problèmes réussis) ou rouge (aucun problème réussi)&nbsp;;</li>
        <li>la réussite de chaque partie calculée selon un algorithme&nbsp;: vert (réussite), orange (réussite partielle), rouge (problèmes non réussis)&nbsp;;</li>
        <li>
          si un élève a sollicité de l'aide pour un problème. Un historique des demandes et des énoncés en question vous permettra de revenir individuellement avec lui sur l'explication de la solution
          qu'il n'a pas comprise.
        </li>
      </ul>

      <h2 id="programmation" className="mt-3">
        La programmation/progression en résolution de problèmes
      </h2>

      <p className="mt-3">
        L'application utilise par défaut une programmation/progression déjà paramétrée au regard de la méthode d'enseignement de la résolution de problèmes M@ths en-vie&nbsp;:{" "}
        <a href="https://www.mathsenvie.fr/la-methode" rel="noreferrer" target="_blank">
          en savoir plus <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>

      <p className="mt-3 fst-italic">Exemple pour le CP</p>

      <div className="d-block text-center mt-3">
        <img src="img/helpProgrammation.webp" alt="Programmation" width="80%" />
      </div>

      <p className="mt-3">Chaque période est découpée en deux, soit 10 modules dans l'année. Pour chaque module sont définis&nbsp;:</p>
      <ul>
        <li>le ou les types de problèmes que l'élève va rencontrer&nbsp;;</li>
        <li>la taille maximale des nombres dans l'énoncé&nbsp;;</li>
        <li>la longueur maximale de l'énoncé (nombre de caractères)&nbsp;;</li>
        <li>le nombre maximal de données dans un problème à étapes&nbsp;;</li>
        <li>la présence ou non de décimaux dans l'énoncé.</li>
      </ul>

      <p className="mt-3">
        Il est possible de personnaliser les différents paramètres de chaque module depuis le profil de l'enseignant. À savoir que toute modification impactera l'outil «&nbsp;Rituel de
        problèmes&nbsp;». Cette fonctionnalité est réservée aux adhérents de l'association M@ths'n Co ayant saisi leur code personnel dans les paramètres de leur profil.{" "}
        <a href="https://www.mathsenvie.fr/association" rel="noreferrer" target="_blank">
          Comment adhérer&nbsp;? <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>{" "}
        Pour chaque niveau, il est possible de réinitialiser les paramétrages et retrouver ceux par défaut.
      </p>

      <p className="mt-3">
        Attention, il y a un risque avec des choix trop restrictifs&nbsp;: il se pourrait qu'il n'y ait pas assez d'énoncés dans la banque pour alimenter l'application. C'est pourquoi, nous invitons
        tous les enseignants utilisateurs de cette application à contribuer à la banque de problèmes collaborative&nbsp;:{" "}
        <a href="https://banque.appenvie.fr" rel="noreferrer" target="_blank">
          cliquer ici <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
        </a>
      </p>
    </div>
  );
}

export default Help;
