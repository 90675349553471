import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { Game, GameData } from "../types";
import { Button, ButtonLink, ErrorPage, ProgressBar, WaitingPage } from "../components";
import { faArrowRotateLeft, faStop } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { api } from "../hooks";
import { useEffect, useState } from "react";
import { GameTypeEnum } from "../utils";
import { Routes } from "../routes";
import { Link } from "react-router-dom";

function EndGame() {
  const location = useLocation();
  const navigate = useNavigate();
  const game = location.state as GameData;

  const [onlyShowBadge, setOnlyShowBadge] = useState(game.type !== GameTypeEnum.Evaluation);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOnlyShowBadge(false);
    }, 2000);

    return () => clearTimeout(timeoutId);
  }, []);

  const getBadge = (score: number) => {
    if (score > 0 && score <= 40) {
      return "img/bronzeBadge.webp";
    } else if (score <= 60) {
      return "img/ironBadge.webp";
    } else {
      return "img/goldBadge.webp";
    }
  };

  const endGame = useMutation<Game>({
    mutationFn: () => {
      return api.game.end(game);
    },
    onSuccess: () => {},
  });

  useEffect(() => {
    if (game) endGame.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  return endGame.isPending ? (
    <WaitingPage />
  ) : (
    <>
      {endGame.isError && <ErrorPage error={endGame.error} />}
      {endGame.isSuccess && (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="w-50">
            <div className="d-flex flex-column">
              {game.type !== GameTypeEnum.Evaluation && (
                <>
                  {!onlyShowBadge && <p className="fw-bold fs-3 mx-auto mb-3">Badge obtenu :</p>}
                  <div className="text-center mw-100 mb-3" style={{ maxHeight: onlyShowBadge ? "50vh" : "25vh" }}>
                    <img className="mw-100 h-100" src={getBadge(endGame.data.score)} alt="Badge" style={{ animation: "2s ease-in-out spin" }} />
                  </div>
                  {!onlyShowBadge && (
                    <div className="mx-auto mb-3" style={{ width: "100%", height: "30px" }}>
                      <ProgressBar game={game} isColumn={false} />
                    </div>
                  )}
                </>
              )}
              {game.type === GameTypeEnum.Evaluation && <p className="fw-bold fs-3 mx-auto mb-3">Évaluation terminée, tu peux maintenant t'entrainer.</p>}
              {!onlyShowBadge && (
                <div className="d-flex column-gap-2">
                  {game.type === GameTypeEnum.Demo ? (
                    <Button
                      icone={faArrowRotateLeft}
                      onClick={() => navigate({ pathname: generatePath(Routes.GAME, { nivelOrRank: game.nivel }), search: location.search }, { replace: true })}
                      label="Nouvel atelier"
                      fontSize={2.6}
                      height="10vh"
                    />
                  ) : (
                    <ButtonLink icone={faArrowRotateLeft} path="GAME" params={{ nivelOrRank: game.student.rank.toString() }} label="Continuer" fontSize={4} height="10vh" />
                  )}
                  {game.origin === "local" &&
                    (game.type === GameTypeEnum.Demo ? (
                      <ButtonLink iconeBgColor="red" icone={faStop} path="ATELIER" label="Arrêter" fontSize={3} height="10vh" />
                    ) : (
                      <ButtonLink iconeBgColor="red" icone={faStop} path="AT_SCHOOL" label="Arrêter" fontSize={8} height="10vh" />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default EndGame;
