/**
 * All available routes for the app
 */

const Routes = {
  BASE: "/",
  HOME: "/home",
  AT_SCHOOL: "/en-classe",
  AT_HOME: "/a-la-maison",
  TEACHER_DASHBOARD: "/tableau-de-bord-enseignant",
  GAME: "/game/:nivelOrRank",
  ATELIER: "/atelier",
  END_GAME: "/resutats",
  HELP: "/aide",

  PROFIL: "/profil",
  PROFIL_PARAMETERS: "/profil/parameters",

  // Others url
  APPS: process.env.REACT_APP_APPS_URL as string,

  APPS_LOGIN: process.env.REACT_APP_APPS_URL + "/login",
  APPS_REGISTER: process.env.REACT_APP_APPS_URL + "/register",

  APPS_PROFIL_PARAMETERS: process.env.REACT_APP_APPS_URL + "/profil/parameters",

  APPS_LEGAL_NOTICES: process.env.REACT_APP_APPS_URL + "/mentions-legales",
  APPS_PRIVACY: process.env.REACT_APP_APPS_URL + "/privacy",
  APPS_CGU: process.env.REACT_APP_APPS_URL + "/cgu",
  APPS_CONTACT: process.env.REACT_APP_APPS_URL + "/contact",
  APPS_CREDITS: process.env.REACT_APP_APPS_URL + "/credits",
} as const;

const Titles = {
  TEACHER_DASHBOARD: "Tableau de bord enseignent",
  HELP: "Aide générale de l'atelier",

  PROFIL: "Profil",
  PROFIL_PARAMETERS: "Profil - Paramètres",
} as const;

const Descriptions = {} as const;

const NoIndex: (keyof typeof Routes)[] = ["HOME", "AT_SCHOOL", "AT_HOME", "ATELIER", "TEACHER_DASHBOARD", "GAME", "END_GAME", "PROFIL", "PROFIL_PARAMETERS"];

export { Routes, Titles, Descriptions, NoIndex };
