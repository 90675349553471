import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FormButtonLoading } from "..";
import { Alert, CloseButton, Modal } from "react-bootstrap";
import { ModalPropsDefault } from "../../types";
import { ModalTypeEnum, UserTypeEnum } from "../../utils";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../routes";
import { useRef, useState } from "react";
import { useModals } from "../../contexts/modals";

type SudoModeModalProps = {
  userType: UserTypeEnum;
};

function SudoModeModal({ show, closeModal, userType }: ModalPropsDefault & SudoModeModalProps) {
  const navigate = useNavigate();
  const [isWrongResponse, setIsWrongResponse] = useState(false);

  const { onModalClose } = useModals();
  const authenticate = (response: number | undefined) => {
    setIsWrongResponse(false);
    if (response === 30) {
      onModalClose(ModalTypeEnum.SudoMode, undefined);
      navigate(Routes.TEACHER_DASHBOARD, { state: { canAccess: "true" } });
      closeModal();
    } else {
      setIsWrongResponse(true);
    }
  };

  const input = useRef<HTMLInputElement>(null);

  return (
    <Modal show={show} onHide={closeModal} size="lg">
      <Modal.Header>
        <h4>Accédez à l'espace {userType ? "enseignant" : "parent"}</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {isWrongResponse && <Alert variant="danger">Le réponse est incorrecte.</Alert>}
        <p className="mb-3">Donner la solution de cette équation : √36 x √25</p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            authenticate(input.current?.valueAsNumber);
          }}
          noValidate
        >
          <div className="mb-3 input-group">
            <span className="input-group-text">
              <FontAwesomeIcon icon={faLock} />
            </span>
            <div className="form-floating">
              <input ref={input} type="number" id="response" className="form-control" placeholder="Réponse" required autoFocus autoComplete="off" />
              <label htmlFor="response" className="form-label">
                Réponse
              </label>
            </div>
          </div>

          <FormButtonLoading isPending={false} label="Accéder" className="btn btn-outline-dark float-end" />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default SudoModeModal;
