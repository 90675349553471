import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { LinearProgress } from "@mui/material";
import { Routes } from "../../routes";
import { UserTypeEnum } from "../../utils";

type ProtectedRouteProps = {
  needAdmin?: boolean;
  userType: UserTypeEnum | null;
};

export default function ProtectedRoute({ children, userType = null, needAdmin = false }: React.PropsWithChildren<ProtectedRouteProps>) {
  const { user, isInitialLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitialLoading && (!user || (needAdmin && !user.isAdmin) || (userType !== null && user?.userType !== userType))) {
      const loginURL = new URL(Routes.APPS_LOGIN);
      loginURL.searchParams.append("redirect", window.location.origin + (process.env.REACT_APP_BASENAME_ROUTER === "/" ? "" : process.env.REACT_APP_BASENAME_ROUTER) + Routes.HOME);
      window.location.replace(loginURL);
    }
  }, [user, isInitialLoading, navigate, needAdmin, userType]);

  let canConnect: boolean = !!user;
  if (needAdmin) canConnect = user?.isAdmin ?? false;
  if (userType) {
    canConnect = user?.userType === userType;
  }

  return canConnect ? children : <LinearProgress className="m-auto" style={{ maxWidth: 960 }} />;
}
