import { generatePath, Link, PathParam } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useEffect, useRef, useState } from "react";
import { Routes } from "../routes";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

type ButtonLinkProps<T extends keyof typeof Routes> = {
  icone: IconProp;
  path: T;
  label: string;
  fontSize: number;
  height: string;
  params?: {
    [key in PathParam<(typeof Routes)[T]>]: string | null;
  };
  disabled?: boolean;
  iconeBgColor?: string;
  transition?: boolean;
};

function ButtonLink<T extends keyof typeof Routes>({
  icone,
  path,
  label,
  fontSize,
  height,
  params = undefined,
  disabled = false,
  iconeBgColor = "#FFB822",
  transition = false,
}: Readonly<ButtonLinkProps<T>>) {
  return (
    <Link to={generatePath(Routes[path], params)} className={"btn btn-primary w-100 p-0" + (disabled ? " disabled" : "")} style={{ borderRadius: 500, height: "fit-content" }}>
      <ButtonBody icone={icone} fontSize={fontSize} height={height} label={label} iconeBgColor={iconeBgColor} isPending={false} transition={transition} />
    </Link>
  );
}

type ButtonHrefProps = {
  icone: IconProp;
  path: string;
  label: string;
  fontSize: number;
  height: string;
  disabled?: boolean;
  iconeBgColor?: string;
};

function ButtonHref({ icone, path, label, fontSize, height, disabled = false, iconeBgColor = "#FFB822" }: Readonly<ButtonHrefProps>) {
  return (
    <a href={path} className={"btn btn-primary w-100 p-0" + (disabled ? " disabled" : "")} style={{ borderRadius: 500, height: "fit-content" }}>
      <ButtonBody icone={icone} fontSize={fontSize} height={height} label={label} iconeBgColor={iconeBgColor} isPending={false} />
    </a>
  );
}

type ButtonProps = {
  icone: IconProp;
  label: string;
  fontSize: number;
  height: string;
  onClick: () => void;
  disabled?: boolean;
  iconeBgColor?: string;
  isPending?: boolean;
};

function Button({ icone, label, fontSize, height, onClick, disabled = false, iconeBgColor = "#FFB822", isPending = false }: Readonly<ButtonProps>) {
  return (
    <button className="btn btn-primary w-100 p-0" onClick={onClick} disabled={disabled || isPending} style={{ borderRadius: 500, height: "fit-content" }}>
      <ButtonBody icone={icone} fontSize={fontSize} height={height} label={label} iconeBgColor={iconeBgColor} isPending={isPending} />
    </button>
  );
}

type ButtonBodyProps = {
  icone: IconProp;
  iconeBgColor: string;
  label: string;
  fontSize: number;
  height: string;
  isPending: boolean;
  transition?: boolean;
};

function ButtonBody({ icone, label, fontSize, height, iconeBgColor, isPending, transition = false }: Readonly<ButtonBodyProps>) {
  const button = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>();

  useEffect(
    () => {
      if (!button.current) {
        // we do not initialize the observer unless the ref has
        // been assigned
        return;
      }

      // we also instantiate the resizeObserver and we pass
      // the event handler to the constructor
      const resizeObserver = new ResizeObserver(() => {
        setWidth(button.current?.offsetWidth);
      });

      // the code in useEffect will be executed when the component
      // has mounted, so we are certain observedDiv.current will contain
      // the div we want to observe
      resizeObserver.observe(button.current);

      // if useEffect returns a function, it is called right before the
      // component unmounts, so it is the right place to stop observing
      // the div
      return function cleanup() {
        resizeObserver.disconnect();
      };
    },
    // only update the effect if the ref element changed
    [button]
  );

  return (
    <div ref={button} className="w-100 d-flex justify-content-between" style={{ padding: "0.5vh", height: "fit-content" }}>
      <div
        className="d-flex align-self-center justify-content-center position-relative"
        style={{ borderRadius: 500, backgroundColor: iconeBgColor, height: "min(" + height + ", calc(0.3 * " + width + "px))", aspectRatio: 1, transition: transition ? "height 2s" : "" }}
      >
        <FontAwesomeIcon spin={isPending} className="align-self-center" icon={isPending ? faSpinner : icone} style={{ height: "60%" }} />
      </div>
      <span
        className="flex-grow-1 text-center align-self-center overflow-hidden px-2"
        style={{ fontSize: "min(calc(" + height + " / 1.5), calc(" + fontSize + "vw  - 5px))", textOverflow: "ellipsis", whiteSpace: "nowrap", transition: transition ? "font-size 2s" : "" }}
      >
        {label}
      </span>
    </div>
  );
}

export { Button, ButtonLink, ButtonHref };
